/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/flatpickr@4.6.13/dist/flatpickr.min.js
 * - /npm/flatpickr@4.6.13/dist/plugins/confirmDate/confirmDate.js
 * - /npm/flatpickr@4.6.13/dist/l10n/es.js
 * - /npm/flatpickr@4.6.13/dist/l10n/fr.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
